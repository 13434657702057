import React from "react"
import { Box } from "rebass"
import ImageGridItem from "./ImageGridItem"

export default ({ images }) => {
  return (
    <Box
      pb={32}
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(430px, 1fr))",
        gridGap: 16,
      }}
    >
      {images.map(item => (
        <ImageGridItem {...{ item }} />
      ))}
    </Box>
  )
}
