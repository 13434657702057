import React, { useEffect, useState } from "react"
import ImageGrid from "../components/ImageGrid"
import Layout from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Button, Heading } from "rebass"
import { Label, Input } from "@rebass/forms"
import EmailModal from "../components/EmailModal"

export default function Home() {
  const data = useStaticQuery(graphql`
    query allPortfolioItems {
      allContentfulPortfolioItem {
        edges {
          node {
            id
            slug
            title
            order
            thumbnail {
              title
              file {
                url
              }
              fixed(height: 720) {
                src
              }
            }
            content {
              title
              description
              file {
                url
              }
              fixed(height: 1500) {
                src
              }
            }
          }
        }
      }
    }
  `)
  const images = data.allContentfulPortfolioItem.edges

  const sortedImages = [...images].sort(
    (a, b) => parseFloat(a.node.order) - parseFloat(b.node.order)
  )
  
  return (
    <Layout>
      <EmailModal />
      <ImageGrid {...{ images: sortedImages }} />
    </Layout>
  )
}
