import React, { useRef, useEffect, useState } from "react"
import { Box, Image, Button, Text } from "rebass"
import Link from "./Link"
import MediaQuery from "react-responsive"

const MuteIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.fill}
    stroke={props.fill}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-volume-x"
  >
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
    <line x1="23" y1="9" x2="17" y2="15" />
    <line x1="17" y1="9" x2="23" y2="15" />
  </svg>
)

const UnmuteIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.fill}
    stroke={props.fill}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-volume-2"
  >
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
    <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07" />
  </svg>
)

const absoluteFillObject = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
}

export default ({ item }) => {
  if (!item.node.content || item.node.content?.length < 1) return null

  const inputEl = useRef(null)
  const playVideo = () => {
    inputEl.current.play()
  }
  const stopVideo = () => {
    inputEl.current.pause()
  }

  const thumbnail = item.node.thumbnail || item.node.content[0]

  const brandLogo = item.node.brandLogo || null

  const [muted, setMuted] = useState(true)

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.muted = muted
    }
  }, [muted])

  const isVideo = !thumbnail.fixed
  const content = isVideo ? (
    <div>
      <MediaQuery minDeviceWidth={768}>
        <div
          onMouseOver={isVideo && playVideo}
          onMouseLeave={isVideo && stopVideo}
        >
          <video
            id="myVideo"
            ref={inputEl}
            playsInline
            muted
            loop
            style={{ ...absoluteFillObject, objectFit: "cover" }}
          >
            <source src={thumbnail.file.url} type="video/mp4" />
            <p>
              Video could not be found.{" "}
              <a href="mailto:info@gregwoodwardphoto.com">Please let me know</a>
            </p>
          </video>
          <a
            onClick={() => setMuted(!muted)}
            id="mute"
            style={{
              zIndex: 1000,
              position: "absolute",
              bottom: 5,
              right: 10,
              cursor: "pointer",
            }}
          >
            {muted ? (
              <MuteIcon fill="#ffffff" />
            ) : (
              <UnmuteIcon fill="#ffffff" />
            )}
          </a>
        </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={768}>
        <video
          playsInline
          autoPlay
          loop
          muted
          style={{ ...absoluteFillObject, objectFit: "cover" }}
        >
          <source src={thumbnail.file.url} type="video/mp4" />
          <p>
            Video could not be found.{" "}
            <a href="mailto:info@gregwoodwardphoto.com">Please let me know</a>
          </p>
        </video>
      </MediaQuery>
    </div>
  ) : (
    <div>
      <Image
        src={thumbnail.fixed.src}
        sx={{
          ...absoluteFillObject,
          objectFit: "cover",
        }}
        alt={item?.node?.title}
      />
    </div>
  )

  return (
    <Box pt="66.666%" sx={{ position: "relative" }}>
      <Box
        sx={{
          ...absoluteFillObject,
        }}
      >
        <Box
          width="100%"
          height="100%"
          sx={{ position: "relative", overflow: "hidden" }}
        >
          {content}
          <MediaQuery minDeviceWidth={768}>
            <div
              onMouseOver={isVideo && playVideo}
              onMouseLeave={isVideo && stopVideo}
            >
              <Link
                sx={{
                  ...absoluteFillObject,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: 0,
                  textDecoration: "none",
                  ":hover,:focus": {
                    opacity: 1,
                  },
                  transition: "opacity .14s ease-out",
                  zIndex: 600,
                }}
                to={`/gallery/${item.node.slug}`}
                bg="rgba(0,0,0,0.3)"
              >
                {/* {brandLogo ? (
                  <Image
                    src={brandLogo.fixed.src}
                    sx={{
                      position: "absolute",
                      height: "10%",
                      bottom: 1,
                      left: 1,
                      zIndex: 500,
                      opacity: 0.9,
                    }}
                  />
                ) : null} */}
                <Text
                  variant=""
                  sx={{
                    fontFamily: "body",
                    color: "white",
                    fontSize: 1,
                    textTransform: "uppercase",
                    letterSpacing: 1.2,
                  }}
                >
                  {item.node.title}
                </Text>
              </Link>
            </div>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={768}>
            <Link
              sx={{
                ...absoluteFillObject,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                opacity: 0,
                textDecoration: "none",
                ":hover,:focus": {
                  opacity: 1,
                },
                transition: "opacity .14s ease-out",
                zIndex: 600,
              }}
              to={`/gallery/${item.node.slug}`}
              bg="rgba(0,0,0,0.3)"
            >
              {/* {brandLogo ? (
                <Image
                  src={brandLogo.fixed.src}
                  sx={{
                    position: "absolute",
                    height: "10%",
                    bottom: 1,
                    left: 1,
                    zIndex: 500,
                    opacity: 0.9,
                  }}
                />
              ) : null} */}
              <Text
                variant=""
                sx={{
                  fontFamily: "body",
                  color: "white",
                  fontSize: 1,
                  textTransform: "uppercase",
                  letterSpacing: 1.2,
                }}
              >
                {item.node.title}
              </Text>
            </Link>
          </MediaQuery>
        </Box>
      </Box>
    </Box>
  )
}
