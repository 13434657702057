import React, { useEffect, useState } from "react"
import { Input, Label, useForm } from "@rebass/forms"
import { Box, Button, Heading } from "rebass"

export default function EmailModal() {
  const [isOpen, setIsOpen] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const isValid =
    firstName?.length > 0 && lastName?.length > 0 && email?.length > 0

  useEffect(() => {
    // Set a timeout to open the modal after 10 seconds
    const timeoutId = setTimeout(openModal, 10000)

    // Clean up the timeout if the component unmounts
    return () => clearTimeout(timeoutId)
  }, []) // Empty dependency array ensures this effect runs only once

  return (
    <div
      className="modal"
      id="myModal"
      style={{
        display: isOpen ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10000,
      }}
    >
      <div
        onClick={closeModal}
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 20,
          paddingTop: 50,
          paddingLeft: 40,
          paddingRight: 40,
          paddingBottom: 50,
        }}
      >
        <div style={{ position: "absolute", top: 10, right: 10 }}>
          <Button onClick={closeModal} style={{ backgroundColor: "white" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill={"#303030"}
              height="12"
              viewBox="0 0 386.667 386.667"
            >
              <path d="M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" />
            </svg>{" "}
          </Button>
        </div>

        <form
          name="mailList"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="mailList" />
          <p hidden>
            <label>
              Don't fill this out: <input name="bot-field" />
            </label>
          </p>
          <Heading fontSize={18} mb={30}>
            Can I send you an email from time to time?
          </Heading>
          <Box mb={32}>
            <Label htmlFor="first_name">First name:</Label>
            <Input
              type="text"
              id="first_name"
              name="first_name"
              value={firstName}
              onChange={e => {
                setFirstName(e.target.value)
              }}
            />
            <Label htmlFor="last_name">Last name:</Label>
            <Input
              type="text"
              id="last_name"
              name="last_name"
              value={lastName}
              onChange={e => {
                setLastName(e.target.value)
              }}
            />
            <Label htmlFor="email">Email:</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
          </Box>
          <Box>
            <Button
              disabled={!isValid}
              fontSize={12}
              type="submit"
              variant={"outline"}
              sx={{ width: "100%", cursor: "pointer" }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </div>
  )
}
